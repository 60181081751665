// import axios from 'axios'
import UserService from '../../services/UserService'

const state = {
  userDetails: null,
  // TODO
  errorNotification: false,
  errorMessage: 'Looks like an error occured... Please check connection and login status and retry',
  successNotification: false,
  successMessage: 'Successfully submitted',
  banNotification: false,
  emoteNotification: false,
  loginChange: false,
  vDialog: false,
  deleteRequested: false,
  posts: [],
  postIndex: 0,
  offset: 0,
  modEnabled: false,
  creatorFilter: null,
  community: {
    game_id: null,
    subpages: []
  },
  communityState: {
    qtcinderella: {
      name: 'QTCinderella',
      routeParam: 'qtcinderella',
      game_id: 3,
      avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/qtcinderella/qt4.jpg',
      sq_avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/qtcinderella/qt4.jpg',
      banner_url: 'https://dzbfexieadma8.cloudfront.net/communities/qtcinderella/qtcinderella_banner.png',
      type: 2
    },
    streamerawards: {
      name: 'The Streamer Awards',
      routeParam: 'streamerawards',
      game_id: 3,
      banner_url: 'https://dzbfexieadma8.cloudfront.net/communities/streamerawards/tsa-text-gold.jpg',
      avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/streamerawards/tsa-avatar-320.png',
      sq_avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/streamerawards/streamerawards_avi.jpg',
      type: 2
    },
    alveus: {
      name: 'Alveus Sanctuary',
      routeParam: 'alveus',
      game_id: 13,
      banner_url: 'https://dzbfexieadma8.cloudfront.net/communities/alveus/alveus_banner.jpg',
      avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/alveus/alveus_avi_sq.jpg',
      sq_avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/alveus/alveus_avi_sq.jpg',
      type: 2
    }
    // avghans: {
    //   name: 'Avghans',
    //   routeParam: 'avghans',
    //   game_id: 11,
    //   avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/avghans/avghans_avi.jpg',
    //   sq_avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/avghans/avghans_avi.jpg',
    //   banner_url: 'https://dzbfexieadma8.cloudfront.net/communities/avghans/avghans_banner.png',
    //   type: 2
    // }
    // maya: {
    //   name: 'Maya',
    //   routeParam: 'maya',
    //   game_id: 8,
    //   sq_avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/maya/maya_avi.jpg',
    //   avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/maya/maya_avi.jpg',
    //   type: 2
    // },
    // nmplol: {
    //   name: 'Nmplol',
    //   routeParam: 'nmplol',
    //   game_id: 10,
    //   avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/nmplol/nmp_avi1.png',
    //   sq_avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/nmplol/nmp_avi1.png',
    //   banner_url: 'https://dzbfexieadma8.cloudfront.net/communities/nmplol/nmp_banner5.jpg',
    //   type: 2
    // },
    // mizkif: {
    //   name: 'Mizkif',
    //   routeParam: 'mizkif',
    //   game_id: 9,
    //   sq_avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/mizkif/mizkif_avi2.jpg',
    //   avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/mizkif/mizkif_avi2.jpg',
    //   type: 2
    // },
    // atrioc: {
    //   name: 'Atrioc',
    //   routeParam: 'atrioc',
    //   game_id: 2,
    //   avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/atrioc/atrioc_avi.png',
    //   sq_avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/atrioc/atrioc_avi.png',
    //   banner_url: 'https://dzbfexieadma8.cloudfront.net/communities/atrioc/atrioc_banner.png',
    //   type: 2,
    //   categories: {
    //     videos: [
    //       {
    //         category_id: 8,
    //         game_id: 2,
    //         name: 'Get Smarter Saturdays',
    //         color: 'green'
    //       },
    //       {
    //         category_id: 9,
    //         game_id: 2,
    //         name: 'Cool Video',
    //         color: 'red'
    //       },
    //       {
    //         category_id: 10,
    //         game_id: 2,
    //         name: 'Community',
    //         color: 'deep-purple'
    //       },
    //       {
    //         category_id: 11,
    //         game_id: 2,
    //         name: 'Ads',
    //         color: 'blue'
    //       }
    //     ],
    //     clips: [
    //       {
    //         category_id: 5,
    //         game_id: 2,
    //         name: 'React Content',
    //         color: 'red'
    //       },
    //       {
    //         category_id: 6,
    //         game_id: 2,
    //         name: 'Big A',
    //         color: 'deep-purple'
    //       },
    //       {
    //         category_id: 7,
    //         game_id: 2,
    //         name: 'Gaming',
    //         color: 'green darken-4'
    //       }
    //     ]
    //   }
    // }
    // gaming: {
    //   name: 'Gaming',
    //   game_id: 6,
    //   routeParam: 'gaming',
    //   avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/gaming/gaming_avi2.png',
    //   sq_avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/gaming/gaming_avi.png',
    //   banner_url: 'https://dzbfexieadma8.cloudfront.net/communities/gaming/gaming_banner2.png',
    //   type: 1,
    //   sec_cat_enabled_videos: true,
    //   sec_cat_enabled_clips: true,
    //   sec_cat_optional: true,
    //   sec_cat_label: 'Genre',
    //   categories: {
    //     videos: [
    //       {
    //         category_id: 35,
    //         game_id: 6,
    //         name: 'Video Essay',
    //         color: 'blue'
    //       },
    //       {
    //         category_id: 36,
    //         game_id: 6,
    //         name: 'Playthrough',
    //         color: 'green'
    //       },
    //       {
    //         category_id: 37,
    //         game_id: 6,
    //         name: 'Competitive',
    //         color: 'red'
    //       },
    //       {
    //         category_id: 38,
    //         game_id: 6,
    //         name: 'Tips / Guide',
    //         color: 'orange'
    //       },
    //       {
    //         category_id: 39,
    //         game_id: 6,
    //         name: 'Meme',
    //         color: 'deep-purple'
    //       },
    //       {
    //         category_id: 40,
    //         game_id: 6,
    //         name: 'Other',
    //         color: ''
    //       }
    //     ],
    //     clips: [
    //       {
    //         category_id: 28,
    //         game_id: 6,
    //         name: 'Win',
    //         color: 'green'
    //       },
    //       {
    //         category_id: 29,
    //         game_id: 6,
    //         name: 'Fail',
    //         color: 'red'
    //       },
    //       {
    //         category_id: 30,
    //         game_id: 6,
    //         name: '???',
    //         color: 'blue'
    //       }
    //     ]
    //   },
    //   secondaryCategories: [
    //     {
    //       category_id: 8,
    //       game_id: 6,
    //       name: 'FPS',
    //       color: ''
    //     },
    //     {
    //       category_id: 9,
    //       game_id: 6,
    //       name: 'Fighting',
    //       color: ''
    //     },
    //     {
    //       category_id: 10,
    //       game_id: 6,
    //       name: 'Speedrunning',
    //       color: ''
    //     },
    //     {
    //       category_id: 11,
    //       game_id: 6,
    //       name: 'Role-Playing',
    //       color: ''
    //     }
    //   ]
    // },
    // smash: {
    //   name: 'Smash Bros.',
    //   game_id: 1,
    //   routeParam: 'smash',
    //   avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/smash/smash_avi.jpg',
    //   banner_url: 'https://dzbfexieadma8.cloudfront.net/communities/smash/smash_banner.png',
    //   sq_avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/smash/smash_sq_avi22.png',
    //   type: 1,
    //   sec_cat_enabled_videos: true,
    //   sec_cat_enabled_clips: true,
    //   sec_cat_optional: false,
    //   sec_cat_label: 'Game',
    //   categories: {
    //     videos: [
    //       {
    //         category_id: 1,
    //         game_id: 1,
    //         name: 'Combo Video',
    //         color: 'red'
    //       },
    //       {
    //         category_id: 2,
    //         game_id: 1,
    //         name: 'Community',
    //         color: 'teal'
    //       },
    //       {
    //         category_id: 3,
    //         game_id: 1,
    //         name: 'Tutorial / Guide',
    //         color: 'orange'
    //       },
    //       {
    //         category_id: 5,
    //         game_id: 1,
    //         name: 'Documentary / History',
    //         color: 'deep-purple'
    //       },
    //       {
    //         category_id: 6,
    //         game_id: 1,
    //         name: 'Competitive Set',
    //         color: 'blue'
    //       }
    //     ],
    //     clips: [
    //       {
    //         category_id: 1,
    //         game_id: 1,
    //         name: 'Combo',
    //         color: 'red'
    //       },
    //       {
    //         category_id: 2,
    //         game_id: 1,
    //         name: 'Funny',
    //         color: 'green'
    //       },
    //       {
    //         category_id: 3,
    //         game_id: 1,
    //         name: 'Tutorial / Tips',
    //         color: 'orange'
    //       },
    //       {
    //         category_id: 4,
    //         game_id: 1,
    //         name: '???',
    //         color: 'indigo'
    //       }
    //     ]
    //   },
    //   secondaryCategories: [
    //     {
    //       category_id: 1,
    //       game_id: 1,
    //       name: 'Melee',
    //       color: ''
    //     },
    //     {
    //       category_id: 2,
    //       game_id: 1,
    //       name: 'Ultimate',
    //       color: ''
    //     },
    //     {
    //       category_id: 3,
    //       game_id: 1,
    //       name: 'Other / Multiple',
    //       color: ''
    //     }
    //   ]
    // },
    // valorant: {
    //   name: 'Valorant',
    //   routeParam: 'valorant',
    //   game_id: 4,
    //   avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/valorant/val_avi.jpg',
    //   banner_url: 'https://dzbfexieadma8.cloudfront.net/communities/valorant/val_banner.png',
    //   sq_avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/valorant/valorant_sq_avi.png',
    //   type: 1,
    //   sec_cat_enabled_videos: false,
    //   sec_cat_enabled_clips: true,
    //   sec_cat_optional: true,
    //   sec_cat_label: 'Event',
    //   categories: {
    //     videos: [
    //       {
    //         category_id: 25,
    //         game_id: 4,
    //         name: 'Tips / Guide',
    //         color: 'orange'
    //       },
    //       {
    //         category_id: 26,
    //         game_id: 4,
    //         name: 'Highlights',
    //         color: 'red'
    //       },
    //       {
    //         category_id: 27,
    //         game_id: 4,
    //         name: 'Esports',
    //         color: 'blue'
    //       },
    //       {
    //         category_id: 28,
    //         game_id: 4,
    //         name: 'Meme',
    //         color: 'deep-purple'
    //       },
    //       {
    //         category_id: 41,
    //         game_id: 4,
    //         name: 'Other',
    //         color: ''
    //       }
    //     ],
    //     clips: [
    //       {
    //         category_id: 22,
    //         game_id: 4,
    //         name: 'Cracked',
    //         color: 'green'
    //       },
    //       {
    //         category_id: 23,
    //         game_id: 4,
    //         name: 'NT',
    //         color: 'red'
    //       },
    //       {
    //         category_id: 24,
    //         game_id: 4,
    //         name: '???',
    //         color: 'blue'
    //       }
    //     ]
    //   },
    //   secondaryCategories: [
    //     {
    //       category_id: 12,
    //       game_id: 4,
    //       name: 'VCT Masters Reykjavik',
    //       color: ''
    //     },
    //     {
    //       category_id: 13,
    //       game_id: 4,
    //       name: 'VCT Game Changers',
    //       color: ''
    //     }
    //   ]
    // }

    // live: {
    //   name: 'Livestreaming',
    //   game_id: 7,
    //   routeParam: 'live',
    //   avi_url: 'https://dzbfexieadma8.cloudfront.net/communities/live/live_avi.png',
    //   banner_url: 'https://dzbfexieadma8.cloudfront.net/communities/live/live_bann_5.png',
    //   type: 2,
    //   sec_cat_enabled_videos: false,
    //   sec_cat_enabled_clips: true,
    //   sec_cat_optional: true,
    //   sec_cat_label: 'Context',
    //   categories: {
    //     videos: [
    //       {
    //         category_id: 19,
    //         game_id: 7,
    //         name: 'Stream Edit / VOD',
    //         color: 'blue'
    //       },
    //       {
    //         category_id: 20,
    //         game_id: 7,
    //         name: 'Compilation',
    //         color: 'green'
    //       },
    //       {
    //         category_id: 21,
    //         game_id: 7,
    //         name: 'React Andy',
    //         color: 'red'
    //       },
    //       {
    //         category_id: 22,
    //         game_id: 7,
    //         name: 'Show / Event',
    //         color: 'orange'
    //       },
    //       {
    //         category_id: 23,
    //         game_id: 7,
    //         name: 'Community Edit',
    //         color: 'blue'
    //       },
    //       {
    //         category_id: 24,
    //         game_id: 7,
    //         name: 'Clip',
    //         color: 'deep-purple'
    //       }
    //     ],
    //     clips: [
    //       {
    //         category_id: 11,
    //         game_id: 7,
    //         name: 'Fail',
    //         color: 'red'
    //       },
    //       {
    //         category_id: 12,
    //         game_id: 7,
    //         name: 'Win',
    //         color: 'green'
    //       },
    //       {
    //         category_id: 13,
    //         game_id: 7,
    //         name: '???',
    //         color: 'blue'
    //       }
    //     ]
    //   },
    //   secondaryCategories: [
    //     {
    //       category_id: 4,
    //       game_id: 7,
    //       name: 'Show / Event',
    //       color: ''
    //     },
    //     {
    //       category_id: 5,
    //       game_id: 7,
    //       name: 'Just Chatting',
    //       color: ''
    //     },
    //     {
    //       category_id: 6,
    //       game_id: 7,
    //       name: 'Gaming',
    //       color: ''
    //     },
    //     {
    //       category_id: 7,
    //       game_id: 7,
    //       name: 'IRL',
    //       color: ''
    //     }
    //   ]
    // },
  }
}

const getters = {
  userDetails: (state) => state.userDetails,
  errorNotification: (state) => state.errorNotification,
  errorMessage: (state) => state.errorMessage,
  successMessage: (state) => state.successMessage,
  loginChange: (state) => state.loginChange,
  getCommunityCategories: (state) => (id) => state.communityState[id].categoryMap,
  getCommunity: (state) => (id) => state.communityState[id]
}

const actions = {

  triggerErrorNotification ({ commit }, trigger) {
    commit('setErrorNotification', { trigger: trigger })
  },

  updateLoginChange ({ commit }, loginChange) {
    commit('setLoginChange', loginChange)
  },

  updateVDialog ({ commit }, dialogState) {
    commit('setVDialog', dialogState)
  },

  async getUserDetails ({ commit }) {
    const res = await UserService.getUserDetails()
    commit('setUserDetails', res.data)
  },

  async logout ({ commit }) {
    try {
      await UserService.logout()
      commit('setUserDetails', null)
      commit('setLoginChange', !state.loginChange)
    } catch (error) {
      commit('setErrorNotification', { trigger: true })
    }
  },

  async deleteAccount ({ commit }) {
    try {
      await UserService.deleteAccount()
      commit('setUserDetails', null)
      commit('setLoginChange', !state.loginChange)
      commit('setDeleteRequested', false)
      commit('setSuccessNotification', { trigger: true, message: 'Account successfully deleted' })
    } catch (error) {
      commit('setErrorNotification', { trigger: true })
    }
  }
}

const mutations = {
  setErrorNotification: (state, payload) => {
    if (payload.message) {
      state.errorMessage = payload.message
    } else if (payload.trigger === true) {
      state.errorMessage = 'Looks like an error occured... Please check connection and login status and retry'
    }
    state.errorNotification = payload.trigger
  },

  setErrorMessage: (state, message) => {
    state.errorMessage = message
  },

  setEmoteNotification: (state, trigger) => {
    state.emoteNotification = trigger
  },

  setSuccessNotification: (state, payload) => {
    if (payload.message) {
      state.successMessage = payload.message
    } else if (payload.trigger === true) {
      state.successMessage = 'Successfully submitted'
    }
    state.successNotification = payload.trigger
  },

  setBanNotification: (state, trigger) => {
    state.banNotification = trigger
  },

  setUserDetails: (state, userDetails) => {
    state.userDetails = userDetails
  },

  setLoginChange: (state, loginChange) => {
    state.loginChange = loginChange
  },

  setVDialog: (state, dialogState) => {
    state.vDialog = dialogState
  },

  setDeleteRequested: (state, value) => {
    state.deleteRequested = value
  },

  setPlayerInfo: (state, obj) => {
    state.community = obj.community
    state.posts = obj.posts
    state.postIndex = obj.postIndex
    state.modEnabled = obj.modEnabled
    state.offset = obj.offset
    state.creatorFilter = obj.creatorFilter
    state.creatorList = obj.creatorList
    state.categoryId = obj.categoryId
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
